import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Badge, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

//components
import withPermissions from '~/hoc/withPermissions';
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter';
import ModalEditor from './ProjectMembersForm';
// import TaskModalEditor from './ProjectTaskCloneForm';
import TaskTemplateList from '../TaskTemplates/TaskTemplateList.jsx';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';
import GenericList from '~/components/GenericList/GenericList';
import ProjectView from './ProjectView';
import ProjectImport from './ProjectImport';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import StatusBox from '../../../elements/StatusBox';

//utils
import { shortDateFormat, totalTimeFormat } from '~/utils/dateFormat';

//assets
import Edit from '../../../assets/img/editing.svg';
import Delete from '../../../assets/img/deleting.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';
import CloseIcon from "~/assets/img/modal-close-icon.svg";
import Tripletex from '../../../assets/img/tripletex.svg'
import Sync from '../../../assets/img/Sync.svg'
import { checkUserPermissions } from '../../../library/stores/userStore.js';

class ProjectsList extends Component {
    constructor(props) {
        super(props);
        const { page, isEdit } = this.props.router.location.state || {};
        this.state = {
            nowEditingId: null,
            modalName: '',
            page: page ? page : 0,
            isPageAddedOrEdited: isEdit ? isEdit : false,
            nowEditingName: null,
            viewTaskTemplates: false,
            showImportComponent: false
        }
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handleTableButtonAction = (id, type, int_id, assignee_id) => {
        const { t } = this.props;
        if (type === 'view')
            return this.startEdit(id, 'project_view');

        if (type === 'clone') {
            return this.startEdit(id, 'tasks');
        }
        if (type === 'edit') {
            if (!this.validateRoleAccess('manage')) return this.startEdit(id, 'project_view');
            this.props.router.navigate(`/admin/projects/${id}`,
                { state: { projects: id, page: this.state.page, isEdit: this.state.isPageAddedOrEdited } });
        }
        if (type === 'remove') {
            this.props.projectStore.remove(id).then((res) => {
                let str = `${t('All data related to this project in Time & Control will be permanently deleted. Are you sure you want to proceed')}?
                ${t('It has some data filled')}. ${t('Tasks')}: ${res.tasks}, ${t('Timelog')}: ${res.timelogs}. `;
                if (int_id) {
                    str = `${t('The project will also be permanently deleted from your bookkeeping system. This means all related data within Time & Control will also be permanently removed. Are you sure you want to proceed with the deletion')}?
                    ${t('It has some data filled')}. ${t('Tasks')}: ${res.tasks}, ${t('Timelog')}: ${res.timelogs}.`;
                }
                confirmAlert({
                    title: this.props.t('Confirm to delete'),
                    message: str,
                    buttons: [
                        {
                            label: this.props.t('Yes'),
                            onClick: () => {
                                return this.props.projectStore.remove(id, true).then((res2) => {
                                    this.props.projectStore.resetLastListLoadTime();
                                    return res2;
                                });
                            },
                        },
                        {
                            label: this.props.t('No'),
                            onClick: () => { },
                        },
                    ],
                });
            });
        }
    };

    startEdit = (id, type, name = null) => {
        if (this.props.userStore.currentUser.user_type === 'member' && type !== 'project_view') return;
        if (type === "task_templates") return this.setState({ viewTaskTemplates: true });
        this.setState({ nowEditingId: id, modalName: type, nowEditingName: name });
    };

    handleSyncWithIntegration = (id) => {
        const { projectStore, t, commonStore } = this.props
        const str = t(`Do You want to Sync Project with Tripletex`);
        confirmAlert({
            title: this.props.t('Confirm to Sync'),
            message: str,
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        return projectStore.saveIntoIntegration({ id: id }).then((res2) => {
                            projectStore.resetLastListLoadTime();
                            commonStore.addNotification(t(res2.message), null, 'success');
                            return res2;
                        });
                    },
                },
                {
                    label: this.props.t('No'),
                    onClick: () => { },
                },
            ],
        })
    }

    renderTableActionButtons = (id, aclAllowed, t, status, int_id, assignee_id) => (
        <div className="actions-center">
            {/* {aclAllowed('project-edit') && (
                <OverlayTrigger
                    key="clone_task"
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{t('Clone Task')}</Tooltip>}
                >
                    <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'clone')}>
                        <i className="fa fa-clone actionblueicon" />
                    </Button>
                </OverlayTrigger>
            )} */}
            {this.props.commonStore.config?.integration_details?.product === 'tripletex' && int_id ?
                <img src={Tripletex} width={20} height={20} alt='int' title={t('Synced with Tripletex')} />
                :
                <OverlayTrigger
                    key="sync-projects"
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{!this.validateRoleAccess('manage') ?
                        t('Unauthorized access! Please Contact Administrator.') : t('Sync with integration')}
                    </Tooltip>}
                >
                    <Button icon_sm fill
                        disabled={!this.validateRoleAccess('manage')}
                        onClick={() => this.handleSyncWithIntegration(id)}>
                        <img src={Sync} alt='sync' />
                    </Button>
                </OverlayTrigger>
            }

            <OverlayTrigger
                key="view_project"
                placement="top"
                overlay={<Tooltip id="tooltip-top">{t("View details")}</Tooltip>}
            >
                <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'view')}>
                    <i className="fa fa-align-left actionblueicon" />
                </Button>
            </OverlayTrigger>
            {aclAllowed('project-edit') && (
                <OverlayTrigger
                    key="update_project"
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">
                        {!this.validateRoleAccess('manage') ?
                            t('Unauthorized access! Please Contact Administrator.') : t("Update Details")}
                    </Tooltip>}
                >
                    <Button
                        icon_sm fill
                        onClick={() => this.handleTableButtonAction(id, 'edit', null, assignee_id)}
                        disabled={!this.validateRoleAccess('manage')}
                    >
                        <img src={Edit} alt={"edit button"} />
                    </Button>
                </OverlayTrigger>
            )}
            {aclAllowed('project-delete') && status === 'inactive' && (
                <OverlayTrigger
                    key="remove_project"
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">
                        {!this.validateRoleAccess('remove') ?
                            t('Unauthorized access! Please Contact Administrator.') : t("Remove Project")}
                    </Tooltip>}
                >
                    <Button icon_sm_delete fill
                        disabled={!this.validateRoleAccess('remove')}
                        onClick={() => this.handleTableButtonAction(id, 'remove', int_id)}>
                        <img src={Delete} alt="delete button" />
                    </Button>
                </OverlayTrigger>
            )}
        </div>
    );

    afterChangeMembers() {
        this.props.projectStore.setFilter('reloadTime', new Date().toString());
        this.props.projectStore.onFilter();
        this.props.projectStore.resetLastListLoadTime();
        this.setState({ nowEditingId: null, modalName: '' });
    }

    //handles generic list page change//
    handlePageChange = (page, isEdit) => {
        this.setState({ page, isPageAddedOrEdited: isEdit });
    };

    handleHeaderTabs = (status) => {
        if (this.isHeaderTabActive(status)) return;
        this.props.projectStore.setFilter('status', status)
        this.props.projectStore.onFilter();
    }

    isHeaderTabActive = (status) => {
        const { appliedFilters } = this.props.projectStore;
        return appliedFilters.status === status
    }

    handleImportModal = (state) => {
        this.setState({ showImportComponent: state })
        this.props.projectStore.resetLastListLoadTime();
    }

    renderProjectHeader() {
        const { t } = this.props;

        const { page, isPageAddedOrEdited } = this.state;
        return (
            <Row className="align-content-center mt-2 gx-0">
                <Col xs={12} md={{ span: 12, offset: 0 }} lg={{ span: 7, offset: 0 }}>
                    <Row className="member_status__summary">
                        <Col
                            xs={4}
                            className="mt-2 mt-sm-0 justify-content-center justify-content-sm-end justify-content-lg-center"
                        >
                            <div className={`highlighted ${this.isHeaderTabActive("") ? 'active' : ""}`} role={'button'}
                                onClick={() => this.handleHeaderTabs("")}
                            >
                                {t('All')} {t('Projects')}: {this.props.projectStore.currentList.project_counts?.all_projects ? this.props.projectStore.currentList.project_counts.all_projects : '...'}
                            </div>
                        </Col>
                        <Col
                            xs={4}
                            className="mt-2 mt-sm-0 justify-content-center justify-content-sm-start justify-content-lg-center"
                        >
                            <div
                                className={`highlightedgreen ${this.isHeaderTabActive("active") ? 'active' : ""}`}
                                role={'button'}
                                onClick={() => this.handleHeaderTabs("active")}
                            >
                                {t('Active')} {t('Projects')}: {this.props.projectStore.currentList.project_counts?.active_projects ? this.props.projectStore.currentList.project_counts.active_projects : '...'}
                            </div>
                        </Col>
                        <Col xs={4} className="mt-2 mt-sm-0 justify-content-center">
                            <div
                                className={`highlightedred ${this.isHeaderTabActive("inactive") ? 'active' : ""}`}
                                role={'button'}
                                onClick={() => this.handleHeaderTabs("inactive")}
                            >
                                {t('Inactive')} {t('Projects')}: {this.props.projectStore.currentList.project_counts?.inactive_projects ? this.props.projectStore.currentList.project_counts.inactive_projects : '...'}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col
                    xs={12} lg={5}
                    className="mt-2 mt-lg-0 text-start text-lg-end"
                >
                    <Row className="gx-0 genericlist_header_xxl_auto justify-content-lg-end" >
                        {this.props.commonStore.config?.integration_details?.product ?
                            <Col className="text-end">
                                <Button
                                    onClick={() => this.setState((state) => ({ showImportComponent: !state.showImportComponent }))}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" className='margin-right-10'><path fill="#2550ac" d="M10 2h4a2 2 0 0 1 2 2v2h4a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8c0-1.11.89-2 2-2h4V4c0-1.11.89-2 2-2m4 4V4h-4v2h4m-2 3l-5 5h3v4h4v-4h3l-5-5Z"></path></svg>
                                    {this.props.t('Import')}
                                </Button>
                            </Col>
                            : null
                        }
                        <Col xs={6} sm={{ span: 4, offset: 4 }} lg={{ span: 3, offset: 0 }} className="text-end">
                            <OverlayTrigger
                                key="add-project"
                                placement="left"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        {!this.validateRoleAccess('create') ?
                                            this.props.t('Unauthorized access! Please Contact Administrator.')
                                            : this.props.t('Add Project')}</Tooltip>}
                            >

                                <Button fill wd
                                    onClick={() => this.props.onAdd(page, isPageAddedOrEdited, this.props.projectStore.currentList)}
                                    disabled={!this.validateRoleAccess('create')}
                                >
                                    <i className="fa fa-plus-circle fa-xl margin-right-10" />
                                    {this.props.t('Add new')}
                                </Button>
                            </OverlayTrigger>
                        </Col>
                        {/* <Col xs={6} sm={4} xxl={3} className="text-end">
                            <Button wd onClick={() => this.startEdit(null, 'task_templates')}>
                                <i className="fa fa-suitcase fa-xl margin-right-10" />
                                {this.props.t('Task Templates')}
                            </Button>
                        </Col> */}
                    </Row>
                </Col>
            </Row>
        )
    }

    validateRoleAccess = (action) => {
        const userStore = this.props.userStore
        switch (userStore.currentUser.user_type) {
            case 'member':
                return false
            case 'pm':
                return checkUserPermissions('md_Project', action);
            default:
                return true;
        }
    }

    render() {
        const { loading, filters, appliedFilters } = this.props.projectStore;
        const { aclAllowed, t, userStore } = this.props;
        const { nowEditingId, modalName, page, isPageAddedOrEdited, nowEditingName, viewTaskTemplates, showImportComponent } = this.state;
        const { config } = this.props.commonStore;
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;
        const statusOptions = config?.client?.data?.project_statuses || [];
        return (
            <Fragment>
                <GenericList
                    columns={[
                        {
                            Header: 'Employer / Project',
                            id: 'name',
                            accessor: ({ id, name, project_number, assignee_id }) => (
                                <span title={name}>
                                    <a
                                        className="list-main-column"
                                        onClick={() => this.handleTableButtonAction(id, 'edit', null, assignee_id)}
                                    >
                                        {project_number} | {name}
                                    </a>
                                </span>
                            ),
                            isSortable: true,
                            maxWidth: 120,
                            minWidth: 140,
                            textAlign: 'start',
                        },
                        {
                            Header: 'Last Updated',
                            id: 'updated_at',
                            accessor: ({ updated_at }) => updated_at && shortDateFormat(updated_at, dateTimeRules),
                            isSortable: true,
                            maxWidth: 100,
                        },
                        {
                            Header: 'Status',
                            id: 'status',
                            accessor: ({ status }) => <StatusBox status={status || ''} />,
                            maxWidth: 80,
                        },
                        {
                            Header: 'Location',
                            id: 'address',
                            accessor: ({ address, post_place, post_number }) => (
                                <span>
                                    {`${address ? `${address} ` : ''}${post_place ? `${post_place} ` : ''}${post_number ? `${post_number} ` : ''}`}
                                </span>
                            ),
                            isSortable: true,
                            maxWidth: 150,
                        },
                        {
                            Header: 'Total Members',
                            id: 'members_amount',
                            accessor: ({ members_amount, id }) => (
                                <span>
                                    <Badge bg className="projectMemberBadge">
                                        {members_amount.toString().length < 2 ? '0' + members_amount : members_amount}
                                    </Badge>
                                    {this.validateRoleAccess('manageMembers') && (
                                        <span
                                            className="m-1 cursor-pointer projectassignbtn"
                                            onClick={() => this.startEdit(id, 'assignees')}
                                        >
                                            {t('Assign')}
                                        </span>
                                    )}
                                </span>
                            ),
                            maxWidth: 100,
                        },
                        // {
                        //     Header: 'Assigned Templates',
                        //     id: 'tasks_amount',
                        //     accessor: ({ task_template_asign_details, id, name, members_amount, status }) => (
                        //         <span>
                        //             <Badge bg className="projectMemberBadge">
                        //                 {task_template_asign_details ? task_template_asign_details.split(',').length < 9 ? `0${task_template_asign_details.split(',').length}` : task_template_asign_details.split(',').length : "00"}
                        //             </Badge>
                        //             <span
                        //                 className={`m-1 ${members_amount > 0 && status === 'active' ? 'cursor-pointer' : 'cursor-no-drop'} projectassignbtn`}
                        //                 onClick={() => {
                        //                     if (members_amount > 0 && status === 'active')
                        //                         this.startEdit(id, 'assign_tasks', name)
                        //                 }}
                        //             >
                        //                 {t('Assign')}
                        //             </span>
                        //         </span>
                        //     ),
                        //     maxWidth: 100,
                        // },
                        {
                            Header: 'Manager',
                            id: 'assignee_id',
                            accessor: ({ assignee_id, manager_first_name, manager_last_name }) => <span>{assignee_id ? `${manager_first_name} ${manager_last_name}` : '-'}</span>,
                            maxWidth: 100,
                        },
                        {
                            Header: 'Total Hours',
                            id: 'total_hours',
                            accessor: ({ total_hours }) => `${totalTimeFormat(total_hours, dateTimeRules)}`,
                            maxWidth: 100,
                        },
                        {
                            Header: 'Actions',
                            id: 'actions',
                            accessor: ({ id, status, int_id, assignee_id }) => this.renderTableActionButtons(id, aclAllowed, t, status, int_id, assignee_id),
                            maxWidth: 120,
                        },
                    ]}
                    header={
                        <GenericFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.projectStore.setFilter(name, value)}
                            onFilter={() => this.props.projectStore.onFilter()}
                            loading={loading}
                            hasStatusFilter={true}
                            statusOptions={statusOptions}
                        />
                    }
                    tableHeader={this.props.t('Projects')}
                    splitHeader={true}
                    forceReload={this.props.projectStore.deleteSuccess}
                    filters={appliedFilters}
                    lastListLoadTime={this.props.projectStore.lastListLoadTime}
                    requestData={(params) => this.props.projectStore.loadList(params)}
                    pageSize={50}
                    handlePageChange={(page, isEdit) => this.handlePageChange(page, isEdit)}
                    page={page} //new page props to maintain page from parent//
                    isPageAddedOrEdited={isPageAddedOrEdited} //to load same page after edit//
                >
                    {aclAllowed('project-add') && (
                        this.renderProjectHeader()
                    )}
                </GenericList>

                <Modal
                    size="lg"
                    dialogClassName="custom-big-modal"
                    show={!!nowEditingId && modalName === 'assignees'}
                    onHide={() => this.setState({ nowEditingId: null, modalName: '' })}
                >
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">{this.props.t('Project members')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            alt='close_button'
                            onClick={() => this.setState({ nowEditingId: null, modalName: '' })}
                        />
                    </Modal.Header>

                    <Modal.Body style={{ minHeight: '80vh' }}>
                        <ModalEditor id={nowEditingId} afterSave={() => this.afterChangeMembers()} />
                    </Modal.Body>
                </Modal>

                {/* <Modal
                    show={!!nowEditingId && modalName === 'tasks'}
                    onHide={() => this.setState({ nowEditingId: null, modalName: '' })}
                    size={'lg'}
                >
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">{this.props.t('Clone task to project')}</Modal.Title>
                        <img src={CloseModalIcon} alt="close icon" className="cursor-pointer" onClick={() => this.setState({ nowEditingId: null, modalName: '' })} />
                    </Modal.Header>

                    <Modal.Body>
                        <TaskModalEditor
                            id={nowEditingId}
                            afterSave={() => this.setState({ nowEditingId: null, modalName: '' })}
                        />
                    </Modal.Body>
                </Modal> */}

                <Modal
                    size="lg"
                    dialogClassName="custom-big-modal"
                    show={!!nowEditingId && modalName === 'project_view'}
                    onHide={() => this.setState({ nowEditingId: null, modalName: '' })}
                >
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">{this.props.t('Project details')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            alt='close_button'
                            onClick={() => this.setState({ nowEditingId: null, modalName: '' })}
                        />
                    </Modal.Header>

                    <Modal.Body style={{ minHeight: '80vh' }}>
                        <ProjectView id={nowEditingId} />
                    </Modal.Body>
                </Modal>

                <Modal
                    size="lg"
                    className="modal right fade modal_revised"
                    show={(!!nowEditingId && modalName === 'assign_tasks') || viewTaskTemplates}
                    onHide={() => this.setState({ nowEditingId: null, modalName: '', nowEditingName: null, viewTaskTemplates: false }, () => this.props.projectStore.resetLastListLoadTime())}
                >
                    <Modal.Header className='modal-header__revised'>
                        <div className='modal-header__revised__closebtn' onClick={() => this.setState({ nowEditingId: null, modalName: '', nowEditingName: null, viewTaskTemplates: false }, () => this.props.projectStore.resetLastListLoadTime())}>
                            <img
                                src={CloseIcon}
                                className="cursor-pointer"
                                alt='close_button'
                            />
                        </div>
                        <Modal.Title>{!viewTaskTemplates ? t('Assign Task Templates') : t('Task Templates')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='mb-2'>
                        <TaskTemplateList projectId={nowEditingId} projectName={nowEditingName} viewTaskTemplates={viewTaskTemplates} />
                    </Modal.Body>
                </Modal>

                {showImportComponent ?
                    <ProjectImport
                        handleImportModal={this.handleImportModal}
                    />
                    :
                    <></>
                }

            </Fragment>
        );
    }
}
export default inject(
    'projectStore',
    'userStore',
    'commonStore',
)(applicationRouter(withPermissions(withLocalization(observer(ProjectsList)))));
